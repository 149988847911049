<template>
  <div class="c-uiTextarea tw-text-start" :class="uiClass">
    <div
      :class="[
        { 'is-invalid': isError && modelValue.length === 0 },
        {
          'focus is-focused withValue tw-select-none':
            withFocusEffect && modelValue && modelValue.length > 0,
        },
        {
          withFocus: isFocused,
        },
        { 'input-group input-group-outline h-11': !labelOnTop },
        textareaGroupClass,
      ]"
    >
      <label
        class="form-label dark:!tw-text-white"
        v-if="label && !placeholder"
        :for="id"
        >{{ label }}</label
      >
      <textarea
        :id="id"
        type="text"
        :placeholder="placeholder"
        v-bind="$attrs"
        class="form-control"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @change="$emit('change', $event.target!.value)"
        @focus="handleFocus"
        @blur="handleBlur"
      />
      <span
        v-if="isTooltip"
        v-b-tooltip.hover
        :title="tooltipText"
        class="tw-absolute -tw-right-2 tw-top-0 -tw-mt-9 tw-mr-2 tw-cursor-pointer tw-text-gray-400"
      >
        <font-awesome-icon
          icon="icon fa-solid fa-circle-info"
          class="tw-mt-2 tw-text-xl"
        />
      </span>
    </div>
    <slot />
    <span
      class="tw-mt-1 !tw-block tw-rounded-lg tw-bg-red-600 tw-px-2 tw-py-1 tw-text-center tw-text-xs tw-uppercase tw-text-white"
      :class="errorClass"
      role="alert"
      v-if="isError && error.length"
    >
      <strong>{{ error }}</strong>
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { TranslateResult } from 'vue-i18n'

@Component({
  emits: ['change', 'update:modelValue'],
})
export default class UITextarea extends Vue {
  @Prop({ required: true })
  public modelValue: string

  @Prop({ required: false })
  public label: string | TranslateResult

  @Prop({ required: false, default: false })
  public labelOnTop: boolean

  @Prop({ required: false })
  public isError: boolean

  @Prop({ required: false })
  public error: string

  @Prop({ required: false })
  public uiClass: string

  @Prop({ required: false })
  public textareaGroupClass: string

  @Prop({ required: false })
  public errorClass: string

  @Prop({ required: false })
  public id: string

  @Prop({ required: false })
  public placeholder: string | TranslateResult

  @Prop({ required: false, default: true })
  public withFocusEffect: boolean

  @Prop({ required: false, default: false })
  public isTooltip!: boolean

  @Prop({ required: false, default: '' })
  public tooltipText!: string

  public isFocused: boolean = false

  public handleFocus(): void {
    this.isFocused = true
  }

  public handleBlur(): void {
    this.isFocused = false
    this.$emit('focusOut')
  }
}
</script>

<style lang="scss" scoped>
.form-label {
  color: var(--header-color);
}

:deep(.form-control) {
  padding: 10px 12px !important;
  border-radius: 0.375rem !important;
  &:focus {
    border: 1px solid #d81b60 !important;
  }
}

.input-group.input-group-outline.is-focused .form-label + .form-control,
.input-group.input-group-outline.is-filled .form-label + .form-control {
  border-color: #d2d6da !important;
  border-top-color: transparent !important;
  box-shadow: inset 1px 0 #d2d6da, inset -1px 0 #d2d6da, inset 0 -1px #d2d6da;
  border-width: 0 !important;
}

.input-group.input-group-outline.is-focused .form-label:before,
.input-group.input-group-outline.is-focused .form-label:after {
  opacity: 1;
  border-top-color: #d2d6da;
  box-shadow: inset 0 1px #d2d6da;
  border-width: 0 !important;
}

.input-group.input-group-outline.is-focused.withValue .form-label {
  color: #000000;
}

.input-group.input-group-outline.is-focused.withFocus .form-label {
  color: #d71b49;
}

.input-group.input-group-outline.is-focused.withFocus .form-label:before,
.input-group.input-group-outline.is-focused.withFocus .form-label:after {
  border-width: 1px !important;
  opacity: 1;
  border-top-color: #d71b49;
  box-shadow: inset 0 1px #d71b49;
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out !important;
}

.input-group.input-group-outline.is-focused.withFocus
  .form-label
  + .form-control {
  border-width: 1px !important;
  border-color: #d71b49 !important;
  border-top-color: transparent !important;
  box-shadow: inset 1px 0 #d71b49, inset -1px 0 #d71b49, inset 0 -1px #d71b49;
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out !important;
}
</style>
