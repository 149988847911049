import client from '@/services/client'
import { isAxiosError } from 'axios'
import { errorsService } from '@/main'
import { errorsEnum } from '@/types/ErrorTypes'
import type {
  FiltersForm,
  ReportForm,
  ReportsDefinition,
} from '@/types/ReportsTypes'

class ReportsRepository {
  public async getReportsDefinitions(): Promise<ReportsDefinition[]> {
    return await client
      .get(`reports/definitions`)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.ReportsDefinitionGet,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getReportsDefinitionsExtensions(
    report_type: string = 'ticket_list'
  ): Promise<string[]> {
    return await client
      .get(`reports/${report_type}/formats`)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.ReportsDefinitionFormatsGet,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async addReport(
    form: ReportForm,
    filters?: FiltersForm
  ): Promise<string[]> {
    const payload = new URLSearchParams()
    if (filters?.filterBy) {
      const acc = [filters?.filterBy]
      payload.append('filterBy', JSON.stringify(acc))
    }

    if (filters?.orderBy) {
      const acc = [filters.orderBy]
      payload.append('orderBy', JSON.stringify(acc))
    }

    if (filters?.searchBy) {
      const acc = [filters.searchBy]
      payload.append('searchBy', JSON.stringify(acc))
    }

    return await client
      .post(`reports`, form, {
        params: payload,
      })
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.ReportsDefinitionFormatsGet,
            response: error.response,
          })
        }
        throw error
      })
  }
}

export default new ReportsRepository()
