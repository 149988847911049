export default {
  tickets: {
    submittedBy: 'Submitted by',
    asset: 'Asset',
    systemCriticality: 'System criticality',
    criticality: 'Criticality',
    notifications: 'Notifications',
    all: 'All',
    new: 'New',
    open: 'Open',
    assigned: 'Assigned',
    verification: 'Verification',
    confirmed: 'Confirmed',
    processed: 'Processed',
    explanatory: 'Explanatory',
    completed: 'Completed',
    referredToExpert: 'Referred to expert',
    none: 'None',
    critical: 'Critical',
    important: 'Important',
    relevant: 'Relevant',
    lowImpact: 'Low impact',
    statuses: {
      1: 'New',
      2: 'Open',
      3: 'Assigned',
      4: 'Verification',
      5: 'Confirmed',
      6: 'Processed',
      7: 'Explanatory',
      8: 'Completed',
      15: 'Referred to expert',
    },
    metric: 'Ticket metric',
    newTicket: {
      chosenAttributes: 'Chosen attributes',
      newAnonymousTicket: 'New anonymous ticket',
      newTicket: 'New ticket',
      institution: 'Institution',
      details: 'Detailed information',
      addDetails: 'Add detailed information',
      institutionPara:
        'Select the company and unit to which you want to assign the ticket.',
      detailsPara:
        'If you have detailed information about the event, enter it here. These can be, for example: IP address, name of the person related to the case, email address, internet domain. Providing information in separate fields will facilitate the processing of the ticket.',
      errorEmptyAttribute: 'Attribute cannot be empty',
      errorEmptyField: 'Fields cannot be empty',
      errorEmptyCompanyUnit: 'Select a company and unit',
      ticketAssigned: 'Ticket has been assigned',
      chooseCoordinator: 'Choose coordinator',
      chooseExpert: 'Choose expert',
      noUsers: 'No users available to select',
      assign: 'Assign',
      identify: 'Identify',
      takeOver: 'Take over',
    },
    tabs: {
      general: 'General',
      identification: 'Identification',
    },
    identification: {
      newAction: 'New action',
      noAssets: 'No assets assigned to ticket',
      listOfActions: 'List of actions taken',
      actionDescription: 'Action description',
      addPermissions: 'Add permissions',
      addNote: 'Add note',
      saveIdentification: 'Save identification',
      finishIdentification: 'Finish identification',
      identificationSaved: 'Identification has been saved',
      revert: 'Revert',
      userIdentificationAssigned: 'User has been assigned to identification',
      userIdentificationDeleted:
        'User has been removed from identification category',
      categoryTransferredToExpert: 'Category has been transferred to expert',
      categoryTransferedToCoordinator:
        'Category has been transferred to coordinator',
    },
    filter: {
      status: {
        all: 'All',
        new: 'New',
        open: 'Open',
        assigned: 'Assigned',
        inVerification: 'In verification',
        verified: 'Verified',
        processed: 'Processed',
        explanatory: 'Explanatory',
        extended: 'Extended',
        expanded: 'Expanded',
        ended: 'Ended',
        renewed: 'Renewed',
        sentToAuthority: 'Sent to authority',
        endedRetention: 'Ended - Retention',
        closedRetention: 'Closed - Retention',
        sentResponse: 'Sent - Response',
        sentExpert: 'Sent to expert',
        returnedExpert: 'Returned from expert',
      },
    },
    modal: {
      header: 'Grant or deny access',
      description: 'Access request',
      user: 'User',
      grantAccess: 'Grant access',
      denyAccess: 'Deny access',
      finishIdentificationCategoryHeader:
        'Add comment to finish category identification',
      revertIdentificationCategoryHeader:
        'Add comment to revert category identification',
      finishIdentificationHeader: 'Add comment to finish identification',
    },
    affairs: {
      affair: 'Affair',
      affairs: 'Affairs',
      affairsAssigned: 'Affairs assigned to ticket',
      assignAffair: 'Assign affair',
      noAffairsAssigned: 'No affairs assigned to ticket',
      affairAssigned: 'Affair has been assigned to ticket',
      affairDeleted: 'Affair has been deleted from ticket',
    },
  },
}
