import type { RouteRecordRaw } from 'vue-router'
import AssetsEditView from '@/views/AssetsEditView.vue'
import { routeNames, routePermissions } from '@/router/types'

export const routes: RouteRecordRaw[] = [
  {
    path: '',
    component: () => import('../components/layouts/DashboardLayout.vue'),
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: routeNames.Dashboard,
        component: () => import('../views/DashboardView.vue'),
      },
      {
        path: '/tickets',
        name: routeNames.Tickets,
        component: () => import('../views/TicketsView.vue'),
        meta: {
          permission: routePermissions.Tickets,
        },
      },
      {
        path: '/tickets/:id',
        name: routeNames.TicketsNew,
        component: () => import('../views/TicketsEditView.vue'),
        meta: {
          permission: routePermissions.Tickets,
        },
      },
      {
        path: '/tasks',
        name: routeNames.Tasks,
        component: () => import('../views/TasksView.vue'),
        meta: {
          permission: routePermissions.Tasks,
        },
      },
      {
        path: '/_assets',
        name: routeNames.Assets,
        component: () => import('../views/AssetsView.vue'),
        meta: {
          permission: routePermissions.Assets,
        },
      },
      {
        path: '/_assets/:id',
        name: routeNames.AssetsEdit,
        component: () => import('../views/AssetsEditView.vue'),
        meta: {
          permission: routePermissions.Assets,
        },
      },
      {
        path: '/risks',
        name: routeNames.Risks,
        component: () => import('../views/RiskTableView.vue'),
        meta: {
          permission: routePermissions.Risks,
        },
      },
      {
        path: '/risks/:id',
        name: routeNames.RiskPreview,
        component: () => import('../views/RiskView.vue'),
        meta: {
          permission: routePermissions.Risks,
        },
      },
      {
        path: '/affairs',
        name: routeNames.Affairs,
        component: () => import('../views/AffairsView.vue'),
        meta: {
          permission: routePermissions.Affairs,
        },
      },
      {
        path: '/affairs/new',
        name: routeNames.AffairsNew,
        component: () => import('../views/AffairsEditView.vue'),
        meta: {
          permission: routePermissions.Affairs,
        },
      },
      {
        path: '/affairs/:id',
        name: routeNames.AffairsEdit,
        component: () => import('../views/AffairsEditView.vue'),
        meta: {
          permission: routePermissions.Affairs,
        },
      },
      {
        path: '/_assets/new',
        name: routeNames.AssetsNew,
        component: () => AssetsEditView,
        meta: {
          permission: routePermissions.Assets,
        },
      },
      {
        path: '/users',
        name: routeNames.Users,
        component: () => import('../views/UsersView.vue'),
        meta: {
          permission: routePermissions.Users,
        },
      },
      {
        path: '/users/:id/edit',
        name: routeNames.UserPermissions,
        component: () => import('../views/UsersEditView.vue'),
        meta: {
          permission: routePermissions.Users,
        },
      },
      {
        path: '/users/:id/logs',
        name: routeNames.UsersLogTable,
        component: () => import('../views/UsersLogsTable.vue'),
      },
      {
        path: '/glossaries',
        name: routeNames.Glossaries,
        component: () => import('../views/GlossariesFormsView.vue'),
        meta: {
          permission: routePermissions.Glossaries,
        },
      },
      {
        path: '/glossaries/form/:id',
        name: routeNames.GlossariesFormEdit,
        component: () => import('../views/GlossariesEditFormView.vue'),
        meta: {
          permission: routePermissions.Glossaries,
        },
      },
      {
        path: '/glossaries/form/:id/:header_number',
        name: routeNames.GlossariesEdit,
        component: () => import('../views/GlossariesEditView.vue'),
        meta: {
          permission: routePermissions.Glossaries,
        },
      },
      {
        path: '/companies',
        name: routeNames.Companies,
        component: () => import('../views/CompaniesView.vue'),
        meta: {
          permission: routePermissions.Companies,
        },
      },
      {
        path: '/companies/new',
        name: routeNames.CompaniesNew,
        component: () => import('../views/CompaniesNewView.vue'),
        meta: {
          permission: routePermissions.Companies,
        },
      },
      {
        path: '/companies/:id/edit',
        name: routeNames.CompaniesEdit,
        component: () => import('../views/CompaniesEditView.vue'),
        meta: {
          permission: routePermissions.Companies,
        },
      },
      {
        path: '/attributes',
        name: routeNames.Attributes,
        component: () => import('../views/AttributesView.vue'),
        meta: {
          permission: routePermissions.Attributes,
        },
      },
      {
        path: '/attributes/:id/edit',
        name: routeNames.AttributesEdit,
        component: () => import('../views/AttributesItemView.vue'),
        meta: {
          permission: routePermissions.Attributes,
        },
      },
      {
        path: '/attributes/new',
        name: routeNames.AttributesNew,
        component: () => import('../views/AttributesItemView.vue'),
        meta: {
          permission: routePermissions.Attributes,
        },
      },
      {
        path: '/parameters',
        name: routeNames.Parameters,
        component: () => import('../views/ParametersView.vue'),
        meta: {
          permission: routePermissions.Parameters,
        },
      },
      {
        path: '/roles',
        name: routeNames.Roles,
        component: () => import('../views/RolesView.vue'),
        meta: {
          permission: routePermissions.Roles,
        },
      },

      {
        path: '/roles/:id/edit',
        name: routeNames.RoleEdit,
        component: () => import('../views/RolesEditView.vue'),
        meta: {
          permission: routePermissions.Roles,
        },
      },
      {
        path: '/consents',
        name: routeNames.Consents,
        component: () => import('../views/ConsentsView.vue'),
        meta: {
          permission: routePermissions.Consents,
        },
      },
      {
        path: '/consents/new',
        name: routeNames.ConsentsNew,
        component: () => import('../views/ConsentsNewView.vue'),
        meta: {
          permission: routePermissions.Consents,
        },
      },
      {
        path: '/consents/:id/edit',
        name: routeNames.ConsentsEdit,
        component: () => import('../views/ConsentsEditView.vue'),
        meta: {
          permission: routePermissions.Consents,
        },
      },
      {
        path: '/consents/:id/details',
        name: routeNames.ConsentDetails,
        component: () => import('../views/ConsentsDetailsView.vue'),
        meta: {
          permission: routePermissions.Consents,
        },
        props: true,
      },
      {
        path: '/identification',
        name: routeNames.Identification,
        component: () => import('../views/IdentificationView.vue'),
        meta: {
          permission: routePermissions.Identification,
        },
        props: true,
      },
      {
        path: '/identification/new',
        name: routeNames.IdentificationNew,
        component: () => import('../views/IdentificationNewView.vue'),
        props: true,
        meta: {
          permission: routePermissions.Identification,
        },
      },
      {
        path: '/identification/:id/edit',
        name: routeNames.IdentificationEdit,
        component: () => import('../views/IdentificationEditView.vue'),
        props: true,
        meta: {
          permission: routePermissions.Identification,
        },
      },
      {
        path: '/profile/settings',
        name: routeNames.ProfileSettings,
        component: () => import('../views/ProfileSettingsView.vue'),
      },
      {
        path: '/calendar',
        name: routeNames.Calendar,
        component: () => import('../views/CalendarView.vue'),
        meta: {
          permission: routePermissions.Tasks,
        },
      },
      {
        path: '/sources',
        name: routeNames.Sources,
        component: () => import('../views/SourcesView.vue'),
        meta: {
          permission: routePermissions.Sources,
        },
      },
      {
        path: '/sources/new',
        name: routeNames.SourcesNew,
        component: () => import('../views/SourcesNewView.vue'),
        meta: {
          permission: routePermissions.Sources,
        },
      },
      {
        path: '/sources/:id/edit',
        name: routeNames.SourcesEdit,
        component: () => import('../views/SourcesEditView.vue'),
        meta: {
          permission: routePermissions.Sources,
        },
      },
      {
        path: '/verify',
        name: routeNames.Verify,
        component: () => import('../views/VerifyView.vue'),
      },
      {
        path: '/notifications',
        name: routeNames.NotificationsSettings,
        component: () => import('../views/NotificationsSettingsView.vue'),
        meta: {
          permission: routePermissions.Notifications,
        },
      },
      {
        path: '/reports',
        name: routeNames.Reports,
        component: () => import('../views/ReportsView.vue'),
        meta: {
          permission: routePermissions.Reports,
        },
      },
    ],
  },
  // {
  //   path: '/assets/:id/edit',
  //   name: 'assetEdit',
  //   component: () => import('../views/AssetsEditView.vue'),
  //   meta: {
  //     auth: true,
  //   },
  // },
  {
    path: '/login',
    name: routeNames.Login,
    component: () => import('../views/LoginView.vue'),
  },
  {
    path: '/login-2fa',
    name: routeNames.Login2FA,
    component: () => import('../views/Login2FAView.vue'),
  },
  {
    path: '/logout',
    name: routeNames.Logout,
    component: () => import('../views/LogoutView.vue'),
  },
  {
    path: '/register',
    name: routeNames.Register,
    component: () => import('../views/RegisterView.vue'),
    meta: {
      toDashboard: true,
    },
  },
  {
    path: '/register',
    name: routeNames.RegisterSelect,
    component: () => import('../views/RegisterSelectView.vue'),
    meta: {
      toDashboard: true,
    },
  },
  {
    path: '/auth/reset-password',
    name: routeNames.ResetPassword,
    component: () => import('../views/ResetPasswordView.vue'),
    meta: {
      toDashboard: true,
    },
  },
  {
    path: '/password/reset/:token',
    name: routeNames.ResetPasswordConfirmation,
    component: () => import('../views/ResetPasswordConfirmationView.vue'),
    meta: {
      toDashboard: true,
    },
  },
  {
    path: '/anonymous-report',
    name: routeNames.TicketsAnonymous,
    component: () => import('../views/TicketsAnonymousReportView.vue'),
  },
  {
    path: '/preview/ticket/:hash',
    name: routeNames.PreviewTicket,
    component: () => import('../views/PreviewTicketView.vue'),
  },
  {
    path: '/terms-of-service',
    name: routeNames.TermsOfService,
    component: () => import('../views/TermsOfServiceView.vue'),
  },
  {
    path: '/privacy-policy',
    name: routeNames.PrivacyPolicy,
    component: () => import('../views/PrivacyPolicyView.vue'),
  },
  {
    path: '/no-access',
    name: routeNames.NoAccess,
    component: () => import('../views/NoAccess.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: routeNames.NotFound,
    component: () => import('../views/404.vue'),
  },
]
