<template>
  <div
    class="c-dashboardHeader tw-mb-5 tw-flex tw-h-[88px] tw-items-center tw-justify-end tw-border-b-greye9 tw-p-5"
  >
    <div
      class="actions tw-flex tw-flex-col-reverse tw-items-end md:tw-flex-row md:tw-items-center"
    >
      <DashboardCounter />
      <HeaderContext @contextChange="changeContext" />
      <div class="notifications tw-relative tw-mx-8">
        <button
          class="icon tw-relative tw-cursor-pointer tw-text-[22px] tw-text-black hover:tw-text-grey7b hover:tw-duration-500 dark:tw-text-white"
          :class="{ 'tw-text-grey7b': areNotificationsShowed }"
          @click="handleNotificationsToggle"
        >
          <font-awesome-icon icon="fa-solid fa-bell" />
          <span
            class="counter badge rounded-pill small tw-absolute -tw-right-2.5 -tw-top-1 !tw-h-5 !tw-w-5 tw-border tw-border-white tw-bg-brand !tw-p-[1px] !tw-pl-0.5 tw-text-center !tw-text-[10px] !tw-leading-4"
          >
            {{ unreadNotifications }}
          </span>
        </button>
        <OnClickOutside @trigger="areNotificationsShowed = false">
          <NotificationsList
            ref="notificationRef"
            v-if="areNotificationsShowed"
            @close="handleNotificationsToggle"
          />
        </OnClickOutside>
      </div>
      <HeaderMenu />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-facing-decorator'
import DashboardCounter from '@/components/layouts/dashboardLayout/DashboardCounter.vue'
import HeaderMenu from '@/components/layouts/header/HeaderMenu.vue'
import HeaderContext from '@/components/layouts/header/HeaderContext.vue'
import NotificationsList from '@/components/layouts/header/notifications/NotificationsList.vue'
import { taskService, userService } from '@/main'
import { OnClickOutside } from '@vueuse/components'
import type { Nullable } from 'vitest'

@Component({
  components: {
    NotificationsList,
    DashboardCounter,
    HeaderMenu,
    HeaderContext,
    OnClickOutside,
  },
  emits: ['contextChange'],
})
export default class DashboardHeader extends Vue {
  public areNotificationsShowed: boolean = false

  @Ref('notificationRef')
  public notificationRef: Nullable<HTMLElement> = null

  public handleNotificationsToggle(): void {
    this.loadNotifications()
    this.areNotificationsShowed = !this.areNotificationsShowed
  }

  public get unreadNotifications(): number {
    return userService.unreadNotifications
  }

  public get isLoading(): boolean {
    return taskService.isLoading
  }
  public async changeContext(): Promise<void> {
    this.$emit('contextChange')
    await this.loadNotifications()
  }

  public async loadNotifications(): Promise<void> {
    await taskService.loadNotifications()
  }

  public async mounted(): Promise<void> {
    await this.loadNotifications()
  }
}
</script>
